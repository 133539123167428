import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {graphql, StaticQuery} from 'gatsby'
import '../styles/style.scss';
import Header from './header'
import Footer from './footer';

const Layout = ({children}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {name: 'description', content: 'Data Science, Managed Cloud Application, Application Development, Internet of Things (IoT), Blockchain, Digital Marketing, Consulting'},
            {name: 'keywords', content: 'Data Science, Managed Cloud Application, Application Development, Internet of Things (IoT), Blockchain, Digital Marketing, Consulting'},
            {name: 'google-site-verification', content: 'IgXub2rI8iQZ1Al8XT32NzBQNJ2k3d1FOK0DOke_89g'},
            {name: 'google-site-verification', content: 'glElomJvncxHLrhPJTl4Oo37sFThJkt-GlRLFHmqaRo'},
            {name: 'facebook-domain-verification', content: 'dofdt38s83jzbujn7hk604yfkdc2vw'},
          ]}
        >
          <html lang="en"/>
          <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5d09cfd5b41bad4c" async="async"></script>
        </Helmet>

        <section className="section-main">
          <Header/>
          <div>
            {children}
          </div>

          <Footer/>
        </section>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout

/*

        <section className="section-main">
          <Header/>

          <div>
            {children}
          </div>

          <Footer/>
        </section>
 */